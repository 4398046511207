import React from "react"
import { FaInstagram, FaEnvelope, FaGithub } from "react-icons/fa";

import ListLink from "./ListLink"

const Footer = () => (
  <footer>
    <div className="container">
      <nav className="footer-nav">
        <ul className="menu-primary">
          <ListLink to="/projects">Projects</ListLink>
          <ListLink to="/about">About</ListLink>
          <ListLink to="/contact">Contact</ListLink>
        </ul>
      </nav>
      <nav className="socials">
        <a aria-label="Instagram" href="https://instagram.com/samwilliams0" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        <a aria-label="Email" href="mailto://hello@samw.com.au"><FaEnvelope /></a>
        <a aria-label="Github" href="https://github.com/sam-williams" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
      </nav>
    </div>
  </footer>
  )

  export default Footer