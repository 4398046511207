import React from "react"
import { Link } from "gatsby"

const ListLink = (props) => (
    <li>
        <Link 
        activeClassName="active"
        to={props.to}>
            {props.children}
        </Link>
    </li>
)

export default ListLink