import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import ListLink from "./ListLink"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <nav className="main-nav">
        <span className="logo"><Link to="/" >{siteTitle}</Link></span>
        <ul className="menu-primary">
          <ListLink to="/projects">Projects</ListLink>
          <ListLink to="/about">About</ListLink>
          <ListLink to="/contact">Contact</ListLink>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
